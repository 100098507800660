import { VectorImageProps } from '@wix/thunderbolt-components';

export const COMP_ID_PLACEHOLDER = '<%= compId %>';
const COMP_ID_PLACEHOLDER_REGEX = new RegExp(COMP_ID_PLACEHOLDER, 'g');

export const replaceCompIdPlaceholder = (str: string, compId: string) =>
  str.replace(COMP_ID_PLACEHOLDER_REGEX, compId);

export const replaceCompIdPlaceholderInSvgContent = (
  vectorImageProps: VectorImageProps,
  id: string,
) => {
  if (vectorImageProps && vectorImageProps.svgContent) {
    vectorImageProps.svgContent = replaceCompIdPlaceholder(
      vectorImageProps.svgContent,
      id,
    );
  }
};
